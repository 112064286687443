<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
      </el-image>
      <!-- 使用方法 -->
      <div class="usage_box">
        <div class="title">暢遊東京必備的景點套票，精選多個人氣設施，交通套票與美食店家，一週內可以任選三個喜歡的設施，出示電子票券QR code就可以輕鬆入場體驗。</div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">使用方法</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
            ●同設施只可以使用一次，相同設施不可再入場<br>
            ●票劵使用效期為購買日起<span>90天內</span><br>
             EX:<span>7/1</span>購買票券⇒票期:<span>7/1~9/28</span><br>
            ●票券有效期限: 使用第一個設施後，<span>7日內</span>須使用完畢<br>
            EX:<span>7/1</span>開始使用，<span>有效期限至7/7</span><br>
            ●有效期間內可任<span>選3項設施</span><br>
            ●注意: 單次購買複數票劵時，當其中一張票劵開始用，所有票劵也將同步開通 (<span>使用效期7天</span>)<br>
            ●票券開通後，7天的使用效期無法超過票券<span>90天</span>的有效期間。<br>
             EX:票券期限<span>7/1~9/28</span>，若<span>9/27</span>開始使用，其使用效期為<span>9/27~9/28</span><br>
            ●無使用完畢不能兌現<br>
            ●請事先於以下連結確認各設施注意事項・營業時間・
            公休日情報
        </div>
      </div>
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt="" />
            </div>
          </el-image>
        </div>
        
        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i">{{title}}</div>
          <hr>
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='注意事項'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='兌換地點' && !contentItem.noColor">{{cItem}}<br></span>
                    <a :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''},{'word-break': 'break-word'}]" target="_blank" :href="cItem" v-show="msg.title==='官方網站' && !contentItem.noColor">{{cItem}}<br></a>
                    <a :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''},{'word-break': 'break-word'}]" target="_blank" :href="cItem" v-show="msg.title==='巴士路線' && !contentItem.noColor">{{cItem}}<br></a>
                    <p class="gfwz" v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- 注意事項特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headImg: require('@/assets/images/hftokyo/zh_tw/havefun_title-tc.png'),
      loadImg: require('@/assets/images/hftokyo/loading.png'),
      dataList: [
        {
          id: 1,
          img: require('@/assets/images/hftokyo/グループ 573.png'),
          title: ['東京迷你世界博物館'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: ['東京都江東區有明1丁目3-33 有明物流中心'] }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [{ cid: 1, text: ['https://www.smallworlds.jp/tw/'] }]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['如因天災地震事故，或飛機停飛、電車誤點，導致本館不可<br>抗力休館時無法退款。'] },
                { cid: 2, text: ['如入場券遺失或被盜不能再次補發門票'] },
                { cid: 3, text: ['退場後無法再次入場。'] }
              ]
            }]
        },
        {
          id: 2,
          img: require('@/assets/images/hftokyo/グループ 574.png'),
          title: ['電子成田利木津巴士單程票'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: ['①成田機場第1航站樓1F到達大廳(南口1)'] }, { cid: 2, text: ['②成田機場第2航站樓1F到達大廳(南口)'] }, { cid: 3, text: ['③成田機場第3航站樓主樓1F'] }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [{ cid: 1, text: ['https://webservice.limousinebus.co.jp/web/en/Top.aspx'] }]
            },
            {
              msgId: 3,
              title: '巴士路線',
              content: [{ cid: 1, text: ['https://www.tripellet.com/limousine/route_list.pdf'] }]
            },
            {
              msgId: 4,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['PDF電子票無法直接乘車，務必到利木津巴士櫃台兌換後乘車。'] },
                { cid: 2, text: ['因根據路線運行班次不同，請事先確認運行狀況。'] },
                { cid: 3, text: ['由於巴士在通常的道路 (包括高速公路) 上行駛，因此平日早晚的自然擁堵或旅遊旺季等，可能會因道路情況而延遲。請留出充裕的時間搭乘。'] }
              ]
            }]
        },
        {
          id: 3,
          img: require('@/assets/images/hftokyo/グループ 575.png'),
          title: ['京急電鐵三崎鮪魚之旅一日遊'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: ['東京都港區高輪3-26-26(品川站)'] }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [{ cid: 1, text: ['https://www.haneda-tokyo-access.com/tc/ticket/discount/maguro-day.html'] }]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['兌換後的乘車券限兌換當日有效。'] },
                { cid: 2, text: ['兌換後的車票無法退款。'] },
                { cid: 3, text: ['加盟店舖的營業時間及休業日有可能更動。使用前請務必與店舖、設施確認後再行光顧。'] },
                { cid: 4, text: ['加盟店舗有人群擁擠的可能性。屆時，需要與一般顧客一同等待。此票券並無優先權。'] },
                { cid: 5, text: ['加盟店鋪有權在該菜單、商品售完時，以其他商品代替的權力。請事先諒解。<br>三崎鮪魚之旅的加盟店鋪情報等，詳情請至以下連結查看。','<br><a style="color: rgb(33, 157, 204);border-bottom: 1px solid;word-break: break-all;" href="https://www.keikyu.co.jp/visit/otoku/otoku_maguro/">https://www.keikyu.co.jp/visit/otoku/otoku_maguro/</a>'] }
              ]
            }
          ]
        },
        {
          id: 4,
          img: require('@/assets/images/hftokyo/グループ 576.jpg'),
          title: ['姆明主題樂園門票 & 西武鐵道旅遊通行券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: ['東京都豐島區南池袋1-28-1'] },{ cid: 2, text: ['西武鐵道 池袋站 (西武池袋旅遊服務中心)'] },{ cid: 3, text: ['特急券售票窗口對面'] },{ cid: 4, text: ['※不可至「姆明主題樂園」進行兌換'] }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [{ cid: 1, text: ['https://www.seiburailway.jp/railways/tourist/chinese/ticket/metsa.html'] }]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['產品內容: 西武線全線自由搭乘1日劵、<br>姆明主題樂園1日通行券、<br>姆明主題樂園往返巴士 (飯能站和東飯能站出發)'] },
                { cid: 2, text: ['本商品為外國人專用，兌換票券時，必須出示旅客護照。'] },
                { cid: 3, text: ['亦可出示護照影本、照片、或是居留證。'] },
                { cid: 4, text: ['無法在換票地點進行票券變更或取消。'] },
                { cid: 5, text: ['票券的使用期限為兌換後2週內。'] },
                { cid: 6, text: ['【數量限定優惠】限定200名贈送「別針徽章」，送完為止。'] }
              ]
            }
          ]
        },
        // {
        //   id: 5,
        //   img: require('@/assets/images/hftokyo/グループ 577.png'),
        //   title: ['KOBE FARBRETON 新宿 3000日圓優惠券'],
        //   message: [
        //     {
        //       msgId: 1,
        //       title: '兌換地點',
        //       content: [{ cid: 1, text: ['東京都新宿區新宿3-20-8 TOPS HOUSE新宿横丁'] }]
        //     },
        //     {
        //       msgId: 2,
        //       title: '官方網站',
        //       content: [{ cid: 1, text: ['https://kobe-farbreton.com/'] }]
        //     },
        //     {
        //       msgId: 3,
        //       title: '注意事項',
        //       isDecimal: true, //是否启用ul的数字前缀
        //       content: [
        //         { cid: 1, text: ['可以享受3000日圓優惠券。'] },
        //         { cid: 2, text: ['若有價差需現場支付差額。'] },
        //         { cid: 3, text: ['有可能會因為店家客滿而無法使用的狀況，請見諒。'] }
        //         ]
        //     }
        //   ]
        // },
        {
          id: 6,
          img: require('@/assets/images/hftokyo/グループ 578.png'),
          title: ['野乃鳥 3000日圓優惠券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '●新宿店',
                '東京都 新宿區 新宿 3-20-8 TOPS HOUSE 新宿横丁',
                '●日本橋',
                '東京都千代田區鍛冶町1-9-19 GEMS日本橋6F'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['野乃鳥 KOBE YAKITORI STAND新宿店'],noColor:true },
                { cid: 2, text: ['https://nonotory.jp/stores/honten/'] },
                { cid: 3, text: ['野乃鳥 日本橋'],noColor:true },
                { cid: 4, text: ['https://nonotory.jp/stores/%e9%87%8e%e4%b9%83%e9%b3%a5-%e6%97%a5%e6%9c%ac%e6%a9%8b/'] }
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受3000日圓優惠券。'] },
                { cid: 2, text: ['若有價差需現場支付差額。'] },
                { cid: 3, text: ['3000日元優惠券可從以下店鋪中任選一家做使用。<br>(野乃鳥 KOBE YAKITORI STAND新宿、野乃鳥 日本橋)'] },
                { cid: 4, text: ['入店時需額外支付小菜費用。'] },
                { cid: 5, text: ['有可能會因為店家客滿而無法使用的狀況，請見諒。'] },
                { cid: 6, text: ['結帳時出示Travel Contents APP掃碼<br>就可享有10%點數回饋。 APP下載連結：<br><a style="color: rgb(33, 157, 204);border-bottom: 1px solid;word-break: break-all;" href="http://onelink.to/xyx76x">http://onelink.to/xyx76x</a>'] },
                ]
            }
          ]
        },
        // 
        {
          id: 7,
          img: require('@/assets/images/hftokyo/グループ 579.png'),
          title: ['東京DOME CITY遊樂設施5次券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '東京都文京區後樂1-3-61 法人Service Counter',
                '(MEETS PORT 2樓)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.tokyo-dome.co.jp/zh-CHT/tourists/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['營業時間與各遊樂設施有可能突然變更，請事先於官網確認。'] },
                { cid: 2, text: ['本商品為外國人專用，兌換票券時，必須出示旅客護照。'] },
                { cid: 3, text: ['本商品限1人使用。多數人使用時須購買人數份票券。<br>(2位時需購買2張)。'] },
                { cid: 4, text: ['請遵照各設施搭乘年齡限制。'] },
                { cid: 5, text: ['SENTAI LAND和期間限定設施不可使用。'] }
                ]
            }
          ]
        },
        {
          id: 8,
          img: require('@/assets/images/hftokyo/JOYPOLIS.jpg'),
          title: ['東京台場JOYPOLIS PASSPORT (入場+遊樂設施無限搭乘) '],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒135-0091 東京都港區台場1丁目6番1號 DECKS Tokyo Beach 3F～5F'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://tokyo-joypolis.com/language/ch/index.html'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['營業時間與各遊樂設施有可能突然變更，請事先於官網確認。'] },
                ]
            }
          ]
        },
      ]
    }
  },
  created () {
    // this.$router.push('/hfPage?id=HFTokyo&l=tc')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-tokyo-1-week-free-pass/tc', '_self')
  }
}
</script>
<style lang="scss" scoped>
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #FFFBA0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1A1311;
    font-weight: 700;
    .title{
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span{
        color: #FA6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
      .el-image {
        width: 100%;
      }
    }
    
    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
        /deep/.gfwz {
          span {
            color: #FBB03B;
          }
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
a:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>